.custom-scrollbar {
    scrollbar-color: #9fa1ac #f1f1f5;
    scrollbar-width: thin;
}
.custom-scrollbar::-webkit-scrollbar {
    width: 5px; /* Mostly for vertical scrollbars */
    height: 5px; /* Mostly for horizontal scrollbars */
}
.custom-scrollbar::-webkit-scrollbar-thumb { /* Foreground */
    background: #9fa1ac;
}
.custom-scrollbar::-webkit-scrollbar-track { /* Background */
    background: #f1f1f5;
}
