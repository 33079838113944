/* RESET extension */
:root {
    --sticky-anchors-height: 60;
}

html {
    font-size: 10px;
    scroll-behavior: smooth;
    scroll-padding-top: calc(var(--sticky-anchors-height) * 1px);
}

body {
    overflow-x: hidden;
}

summary::marker,
summary::-webkit-details-marker {
    display: none;
}

select {
    appearance: none;
}

input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
    -webkit-appearance: none;
    appearance: none;
    margin: 0;
}

input[type='number'] {
    -moz-appearance: textfield;

    &:hover {
        -moz-appearance: textfield;
    }
}