.env-development {
    body {
        &:before {
            position: fixed;
            top: 0;
            left: 0;
            padding: 5px;
            z-index: 9999;
            content: '[ -XS ]';
            background: #000;
            color: #fff;
            display: block;

            @screen xs {
                content: '[ XS+ / 360+ ]';
            }
            @screen xsm {
                content: '[ XSM+ / 540+ ]';
            }
            @screen sm {
                content: '[ SM+ / 640+ ]';
            }
            @screen md {
                content: '[ MD+ / 768+ ]';
            }
            @screen lg {
                content: '[ LG+ / 980+ ]';
            }
            @screen xl {
                content: '[ XL+ / 1200+ ]';
            }
            @screen 2xl {
                content: '[ 2XL+ / 1440+ ]';
            }
        }
    }
}

