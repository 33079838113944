/* WYSIWYG */
.wysiwyg, .wysiwygContent {
    p, ul, ol, img {
        @apply font-normal mb-12 md:mb-16 text-base text-gray-70;
    }
    strong {
        @apply font-semibold;
    }
    h2 {
        @apply font-semibold my-8 text-xl sm:text-2xl text-black text-center;
    }
    h3 {
        @apply font-semibold my-4 text-base text-black text-center
    }
    h4 {
        @apply font-semibold my-2 text-xs text-black text-center uppercase;
    }
    ul {
        list-style-position: inside;
        list-style-image: url('../../public/img/list.svg');
    }
    table {
        @apply w-full mb-16;
        td, th {
            @apply text-sm text-gray-70;
        }
        th {
            @apply font-semibold;
        }
    }
    .video {
        @apply mb-6;
        iframe {
            @apply w-full aspect-video;
        }
    }
    .img-container {
        @screen md {
            margin-left: -6rem;
            margin-right: -6rem;
        }
        @screen lg {
            margin-left: -8rem;
            margin-right: -8rem;
        }
        @screen xl {
            margin-left: -9.5rem;
            margin-right: -9.5rem;
        }
        @screen 2xl {
            margin-left: -12rem;
            margin-right: -12rem;
        }
    }
}
/* BRANCH PICKER DATA STYLES */
.pickup-points__item-note,
.pickup-points__item-opening-hours {
    p, ul {
        @apply mb-3;
    }
    strong {
        @apply font-semibold;
    }
    ul {
        list-style-position: inside;
        list-style-image: url('../../public/img/list-pickup-note.svg');
    }
}

/* NOTE LINK */
.note {
    a {
        @apply text-black underline;
    }
}

