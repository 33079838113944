.is-sticky--anchors {
    .product-page__sections-nav {
        @screen xl {

            .sections-nav__nav,
            .order-box {
                @apply flex justify-between;
            }

            .order-box {
                @apply ml-auto;
            }
        }
    }

    /* .anchor-target {
        scroll-margin-top: 10rem;
    } */
}

.is-sticky--product-form {
    padding-bottom: 7rem;

    @screen lg {
        padding-bottom: 0;
    }
}

.is-sticky--cart {
    padding-bottom: 8.6rem;

    @screen xl {
        padding-bottom: 0;
    }
}